<template>
  <div id="choose-payment-type-container">
    <h5 id="payment-type-title">{{ labels.payment.paymentType[language] }}</h5>
    <div
      v-if="tableType=='TABLE_AT_HOTEL' || tableType=='UMBRELLA_AT_HOTEL' || tableType=='HOTEL_ROOM'"
      class="payment-option"
    >
      <input
        class="input"
        type="radio"
        value="ROOM_CHARGE"
        @change="onChangePaymentType"
        v-model="paymentType"
      />
      <h6 @click="selectPaymentType('ROOM_CHARGE')">
        {{tableType=='HOTEL_ROOM' ? labels.payment.chargeToRoom[language]: labels.payment.chargeAtRoom[language]}}
        <font-awesome-icon
          class="payment-type-icon"
          :class="{ greenColor: paymentType == 'ROOM_CHARGE' }"
          icon="bed"
          @click="selectPaymentType('ROOM_CHARGE')"
        />
      </h6>
    </div>
    <!-- <div v-if="(tableType=='TABLE_AT_HOTEL' || tableType=='UMBRELLA_AT_HOTEL') && tableOpeningsEnabled">
      <div class="payment-option" style="margin-right: 16px;">
        <input
          class="input"
          type="radio"
          value="CASH"
          @change="onChangePaymentType"
          v-model="paymentType"
        />
        <h6 @click="selectPaymentType('CASH')">{{ labels.payment.cashOrCard[language] }}</h6>
      </div>
    </div>-->
    <div>
      <div v-if="paymentTypes.includes('CASH')" class="payment-option" style="margin-right: 16px;">
        <input
          class="input"
          type="radio"
          value="CASH"
          @change="onChangePaymentType"
          v-model="paymentType"
        />
        <h6 @click="selectPaymentType('CASH')">{{ labels.payment.cash[language] }}</h6>
        <font-awesome-icon
          class="payment-type-icon"
          :class="{ greenColor: paymentType == 'CASH' }"
          icon="money-bill"
          @click="selectPaymentType('CASH')"
        />
      </div>
      <div class="payment-option" v-if="paymentTypes.includes('CARD_TERMINAL')">
        <input
          class="input"
          type="radio"
          value="CARD_TERMINAL"
          @change="onChangePaymentType"
          v-model="paymentType"
        />
        <h6 @click="selectPaymentType('CARD_TERMINAL')">
          {{ tableType=='DELIVERY' || tableType=='DELIVERY_TABLE' ? labels.payment.cardOnDelivery[language] : labels.payment.cardAtTheStore[language] }}
          <font-awesome-icon
            class="payment-type-icon"
            :class="{ greenColor: paymentType == 'CARD_TERMINAL' }"
            icon="credit-card"
            @click="selectPaymentType('CARD_TERMINAL')"
          />
        </h6>
      </div>
    </div>

    <div v-if="hasOnlinePaymentsByCard" class="payment-option">
      <input
        class="input"
        type="radio"
        value="CARD"
        @change="onChangePaymentType"
        v-model="paymentType"
      />
      <h6 @click="selectPaymentType('CARD')">
        {{ labels.payment.cardOnline[language] }}
        <font-awesome-icon
          class="payment-type-icon"
          :class="{ greenColor: paymentType == 'CARD' }"
          icon="credit-card"
          @click="selectPaymentType('CARD')"
        />
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChoosePayment",
  props: ["hasOnlinePaymentsByCard", "tableType", "tableOpeningsEnabled", "tableShowsPaymentsOptionNoMatterWhat", "paymentTypes"],
  components: {
  },
  data() {
    return {
      paymentType: 'CASH'
    }
  },
  watch: {

  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    }

  },
  methods: {
    selectPaymentType(paymentType) {
      this.paymentType = paymentType;
      this.$emit("payment-type-select", this.paymentType)
    },
    onChangePaymentType() {
      this.$emit("payment-type-select", this.paymentType)
    }
  },
  mounted() {
    if (((this.tableType == 'TABLE_AT_HOTEL' || this.tableType == 'UMBRELLA_AT_HOTEL') && (!this.tableOpeningsEnabled || this.tableShowsPaymentsOptionNoMatterWhat)) || this.tableType == 'HOTEL_ROOM') {
      this.paymentType = 'ROOM_CHARGE';
      this.$emit("payment-type-select", 'ROOM_CHARGE')
    } else if (!this.paymentTypes.includes('CASH')) {
      this.paymentType = 'CARD_TERMINAL';
      this.$emit("payment-type-select", 'CARD_TERMINAL')
    }
  }
};
</script>

<style scoped>
#choose-payment-type-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#payment-type-title {
  font-weight: 600;
  font-size: 15px;
}

.payment-option {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.payment-type-icon {
  margin-left: 6px;
  margin-top: 3px;
  color: rgb(95, 95, 95);
}

.greenColor {
  color: #198b4a !important;
}

.payment-option > h6 {
  font-size: 14px;
}

.input {
  accent-color: #198b4a;
  margin-bottom: 6px;
  margin-right: 5px;
}
</style>